* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, sans-serif;
}

body {
  margin: 0;
  background: #f0f2f5;
}

.page__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.page {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  gap: 20px;
}

.save {
  grid-column: 1 / span 2;
  padding: 16px;
  font-size: 15px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
  background: #198cff;
  border: none;
  border-radius: 4px;
}

.save:hover {
  background: #0077f0;
}

.save:disabled,
.save[disabled] {
  background: #198cff;
  opacity: 0.3;
}

.ghost {
  padding: 8px;
  font-size: 15px;
  line-height: 18px;
  color: black;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background: #fff;
  border-radius: 4px;
}

.what {
  position: absolute;
  right: 12px;
  bottom: 12px;
}

.label {
  position: absolute;
  right: 12px;
  bottom: 12px;
  z-index: 100;
  align-self: flex-start;
}

.ghost:hover {
  background: #f0f2f5;
}

.work {
  position: relative;
  display: flex;
  flex-direction: column;
}

.viewport {
  width: 500px;
  height: 500px;
  border-radius: 4px;
}

.mask__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}

.mask {
  width: 100px;
  height: 100px;
  padding: 8px;
  margin-top: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  border-radius: 4px;
}

.mask_active {
  border: 1px solid rgba(196, 204, 212, 0.5);
}

@media (max-width: 1125px) {
  .page {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr;
    width: calc(100% - 24px);
    gap: 20px;
    margin: 12px;
  }

  .save {
    grid-column: 1;
  }

  .mask__container {
    flex-direction: row;
  }

  .viewport {
    width: calc(100vw - 64px);
    height: calc(100vw - 64px);
  }

  .label {
    position: relative;
    right: 0px;
    bottom: 0px;
    align-self: center;
    width: 100%;
    margin-top: 24px;
  }

  .page__container {
    display: block;
    width: 100%;
    height: initial;
  }

  .what {
    position: relative;
    right: initial;
    bottom: initial;
    display: block;
    width: calc(100% - 24px);
    margin: 12px;
    margin-bottom: 24px;
  }
}
